import React from "react";
import { Link, graphql } from "gatsby"
import { GatsbySeo, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Hidden from "@material-ui/core/Hidden";

// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";
import Button from 'components/CustomButtons/Button.js';

import useIsClient from 'hooks/useIsClient';

import styles from "assets/jss/material-kit-pro-react/views/productCategoryTemplateStyles.js";

export const query = graphql`
  query {
    bannerImage: imageSharp(resize: {originalName: {eq: "bg_banner_warmers.jpeg"}}) {
        gatsbyImageData(quality: 50)
    }
    bannerImageMobile: imageSharp(resize: {originalName: {eq: "bg_banner_warmers.jpeg"}}) {
        gatsbyImageData(width: 1200)
    }
    allContentfulCategory(
      filter: {isHidden: {eq: false}},
      sort: { fields: order, order: ASC }
    )
    {
        edges {
            node {
                name
                slug
                richDescription {
                  raw
                }
                image {
                  gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
        totalCount
    }
  }
`

const useStyles = makeStyles(styles);

export default function ShopTemplate(props) {

  var appendToH1;
  if (props.pageContext.locale.ogLanguageCode === "en_CA") {
      appendToH1 = "Scentsy Catalog Canada";
  } else {
      appendToH1 = "Scentsy Catalog USA";
  }

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(props.data.bannerImageMobile)
    } else {
      bannerImage = getSrc(props.data.bannerImage)
    }
  }

  const classes = useStyles();

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

  return (
    <div>
      <GatsbySeo
        title={'SCENTSY Catalog Fall/Winter 2022' + props.pageContext.locale.appendToTitle}
        description='Looking for the Fall / Winter 2022 Scentsy catalog? Explore fragrance and home decor ideas. Browse and shop now. Direct shipping to Canada, USA, and Mexico.'
        canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
        language={props.pageContext.locale.isoLanguageCode}
        languageAlternates={[
          {
            hrefLang: props.pageContext.locale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
          {
            hrefLang: props.pageContext.altLocale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
        }
        ]}
        openGraph={{
          type: 'website',
          title: 'SCENTSY Catalog Fall/Winter 2022' + props.pageContext.locale.appendToTitle,
          description: 'Looking for the Fall / Winter 2022 Scentsy catalog? Explore fragrance and home decor ideas. Browse and shop now. Direct shipping to Canada, USA, and Mexico.',
          url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          site_name: 'SCENTSY Online Store | Home Fragrance Biz',
          locale: props.pageContext.locale.ogLanguageCode,
          images: [
            {
              url: siteMetadata.siteUrl + '/files/images/scentsy-catalog.jpeg',
              alt: 'Picture of Spring/Summer 2021 Scentsy Catalog | Home Fragrance Biz, Independent Scentsy Consultant',
              width: 2048,
              height: 1536
            }]
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Home',
            item: 'https://homefragrancebiz.com',
          },
          {
            position: 2,
            name: 'SCENTSY catalog',
          },
        ]}
      />
      <HeaderTemplate
        locale={props.pageContext.locale}
        location={props.location}
      />
      <Parallax
        filter="dark"
        image={bannerImage}
        className={classes.parallax}
      >
        <div className={classes.container}>

          <HeaderScentsy />

            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <div className={classes.brand}>
                  <Hidden only={['sm','md','lg','xl']} implementation="js">
                    <Typography variant="h5" component="h1" className={classes.title}>
                      shop our SCENTSY catalog
                    </Typography>
                  </Hidden>
                  <Hidden only={['xs']} implementation="js">
                    <h1 className={classes.title}>
                      shop our SCENTSY catalog
                    </h1>
                  </Hidden>
                  <Hidden only={['xs']} implementation="js">
                    <Typography variant="caption" component="p" className={classes.titleDescription}>
                      Looking for the complete Fall / Winter 2022 Scentsy catalog? Subscribe to our mailing list and download now
                      <br /><a href="#subscribe"><Button color="primary" round>Subscribe</Button></a>
                    </Typography>
                  </Hidden>
                </div>
              </GridItem>
            </GridContainer>

        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <Paper className={classes.paper}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Typography color="textPrimary" variant="subtitle2" component="h1">
                SHOP
              </Typography>
            </Breadcrumbs>

            <Hidden smDown implementation="js">
              <font color="#777777" size="1"><i> {appendToH1}</i></font>
            </Hidden>

            <hr></hr>

            <Hidden xsDown implementation="js">

              <Typography variant="h2">
                   <font size="10">Fill your life with fragrance</font>
              </Typography>

              <Typography variant="body2" component="h3">
                  <font size="1">Scentsy has expanded its offerings to include a premium fan fragrance diffuser system
                  that lets you enjoy Scentsy wherever you roam. Go unplugged with the Scentsy Fragrance Flower,
                  car fresheners, room sprays, and more. Scentsy has a line of stunning ultrasonic diffusers
                  for use with natural and essential oils and blends, super concentrated laundry care,
                  and a line of cleaning products that includes kitchen soap, hand soap, counter cleaner,
                  bathroom cleaner, and all-purpose cleaner concentrate. In addition, Scentsy offers a body line featuring
                  favourite fragrances, and a fun line of kids products including
                  buddies (stuffed animals), Buddy clips (for backpacks and bags), and more!
                  </font>
              </Typography>
            </Hidden>

            <GridContainer>

               <GridItem md={12} sm={12}>
                <GridContainer>
                  {props.data.allContentfulCategory.edges.map((edge) => (
                    <GridItem md={4} sm={4} key={edge.node.slug}>
                      <Link to={props.pageContext.locale.path + "/shop/" + edge.node.slug + "/" }>
                        <Card plain product>
                          <CardHeader noShadow image>
                              <GatsbyImage image={edge.node.image.gatsbyImageData} alt={edge.node.name} />
                          </CardHeader>
                          <CardBody plain>
                              <Typography variant="h6" component="h2" className={classes.cardTitle}>
                              {edge.node.name}
                              </Typography>

                            <p className={classes.description}>
                              {edge.node.shortDescription}
                            </p>
                          </CardBody>

                        </Card>
                    </Link>
                  </GridItem>

                  ))}
                </GridContainer>
              </GridItem>
            </GridContainer>
      </Paper>

      </div>

      <FooterTemplate
        locale={props.pageContext.locale}
      />
      
    </div>
  );
}
